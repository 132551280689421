
import { Component, Prop } from 'vue-property-decorator';
import { getNameByEmail } from '@/utils/users';
import { formatLocalDate } from '@/utils/dates';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { mixins } from 'vue-class-component';
import GenericMixin from '@/lib/mixins/GenericMixin';
import { getComponent } from '@/utils/helpers';

@Component({
  components: {
    TasqMiniView: () => getComponent('tasqs/TasqMiniView'),
  },
})
export default class WorkTicketItem extends mixins(GenericMixin) {
  @Prop({ type: Boolean, required: false, default: false })
  setFixHeight?: boolean;

  @Prop({ type: Object, required: false }) tasq?: any;

  chipMap = {
    Blocked: 'error',
    Complete: 'success',
    'In Progress': 'info',
    Open: 'warning',
  }

  get routeQueryType() {
    return this.$route.query.type;
  }

  get isWorkTicketActive(): boolean {
    return (
      !!(
        tasqsListModule.activeWorkTicket
        && this.$route.name !== 'Tasqs'
        && tasqsListModule.activeWorkTicket.id === this.workTicket.id
      ) || this.$route.params.id === this.workTicket.id
    );
  }

  get workTicket(): any {
    return tasqsListModule.workTicketById(this.tasq?.id);
  }

  get chipType() {
    return this.chipMap[this.workTicket.status];
  }

  get tagClasses() {
    if (this.workTicket.status === 'Blocked') {
      return 'bg-tasqBlockedBg text-tasqBlockedText';
    }
    if (this.workTicket.status === 'Complete') {
      return 'bg-tasqCompleteBg text-tasqCompleteText';
    }
    if (this.workTicket.status === 'In Progress') {
      return 'bg-tasqInProgressBg text-tasqInProgressText';
    }
    return 'bg-tasqOpenBg text-tasqOpenText';
  }

  fullName(): string {
    // @ts-ignore
    let assignee = '';
    if (this.workTicket?.assignee) {
      assignee = getNameByEmail(this.workTicket?.assignee);
    }
    return assignee;
  }

  formatLocalDate = formatLocalDate;

  goToWorkTicketPage() {
    if (this.$route.name !== 'WorkTicketCalendar') {
      this.$router.push({
        name: 'WorkTickets',
        params: {
          id: this.workTicket?.id || '',
        },
        query: { type: 'workticket' },
      });
    }
    tasqsListModule.setActiveWorkTicket(this.workTicket);
  }
}
